.carousel-container {
    width: 100%;
    max-width: 600px; 
    margin: 0 auto;
  } 
  
   .carousel-photo {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }


