@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

html {
  box-sizing: border-box;
  padding:0;
  margin:0;
  background-color: #0a192f;
}

*, *::before, *::after {
  box-sizing: inherit;
}
