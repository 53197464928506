@import url("https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sevillana&display=swap");

#hi-para {
  color: #7ab2b2;
  margin: 0;
  display: block;
}

.nametag {
  color: azure;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 2.5rem;
}
.landingmaindiv {
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  margin: auto;
  font-style: italic;
  font-family: Edu TAS Beginner;
  width: 70vw;
  height: 100vh;
}

.firstdiv{
    width: 100%;
    user-select: none;
}

.gif{
    display: none;
}

#downarrow{
    padding-top: 20px;
    position: absolute;
    left: 45%;
    bottom: 10%;
    animation: bounce 2s infinite;
    user-select: none;
}

.downlogo{
    width: 60px;
    height: 60px;
}


@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
.fade-in-out {
  animation: fadeInOut 2s ease-in-out infinite;
  color: azure;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
@keyframes slideInOut {
  0%,
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
}

#resumebutton {
  background-color: rgb(73, 120, 120);
  color: white;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  text-align: centre;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#resumebutton:hover {
  background-color: rgb(55, 90, 90);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2);
}


#resumeid {
  color: inherit;
  text-decoration: none; /* Remove underline from link */
}

#resumeid:hover {
  text-decoration: underline; /* Underline link on hover */
}

.intro-paragraph {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgb(143, 138, 138);
  line-height: 1.25;
  max-width: 600px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.project-div{
  position: relative;
  top: 50px;
}

.singlelogo{
  transform: scale(1.05);
}
#skills{
  font-size: 3rem;
}
@media screen and (min-width: 100px) and (max-width: 900px) and (min-height: 750px) {
    .firstdiv{
      margin-top: 8vh;
    }
}

@media screen and (min-width: 600px) and (max-width: 1024px) and (min-height: 750px) {
  .firstdiv,.seconddiv{
    margin-top: 18vh;
  }

}
@media screen and (min-width: 900px) {
  .project-div{
    position: relative;
    top: 150px;
}
#workGIF{
  height: fit-content;
}
.landingmaindiv{
    padding-top: 140px;
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.firstdiv{
    width: 50%;
    user-select: none;
}
.seconddiv{
  width: 50%;
  user-select: none;
}

#downarrow{
  display: block;
    padding-top: 0px;
    position: absolute;
    top: 90vh;
    left: 45vw;
}
.gif{
  width: 100%;
  display: inline-block;
  height: 100%;
  user-select: none;
  border-radius: 15%;
}

}

@media screen and (min-width: 1600px) {

  .firstdiv{
    width: 50%;
    user-select: none;
    padding-top: 10%;
}
#hi-para, .fade-in-out {
  font-size: 2rem;
}
.nametag{
  font-size: 3.3rem;
}
#resumebutton {
  padding: 15px 30px;
  font-size: large;
}
#workGIF{
  padding-top: 5%;
}
}