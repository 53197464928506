.main-exp{
    display: block;
    margin-top: 200px;
}

.exp-main-div{

    display: flex;
    flex-direction: row;
    width: 65%;
    height: auto;
    margin: auto;
    margin-top: 50px;
    
}

.heading{
    color: #e6f1ff;
    font-size: larger;
}

.comp-name{
    color: azure;
    font-size: 1rem;
    margin:0;
    padding: 20px; 
    user-select: none;
}
.left-name-div{
    padding-top: px;
    width: 30%;
    display: flex;
    flex-direction: column;
}

.right-descp-div{
    width: 70%;
    margin-bottom: 30px;
    min-height: 550px;
    max-height: 550px;
    overflow-y: auto;
}

.show{
    user-select: none;  
    display: block;
    text-align: Left;

}
ul{
    list-style: none;
    padding: 0;
}
li{
    padding: 0;
    position: relative;
    margin-bottom: 10px;
    color: #8892b0;
    padding-left: 20px;;
}
.li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: #6fdbc2;
}
.date{
    color: #a8b2d1;
}

.hide{
    display: none;
}

.showcomp{
    border-left: 3px solid #6fdbc2;
}

.hidecomp{
    border-left: 3px solid #233554;
}

.exp-mob-div{
    display: none;
}
@media screen and (max-width: 600px) {
    .left-name-div{
        display: none;
    }
    .right-descp-div{
        width: 100vw;
        min-height: 700px;
        max-height: 700px;
        overflow-y: auto;
    }
    .exp-main-div{
        margin-top: 0px;
        width: 85%;
    }
    .exp-mob-div{
        display: flex;
        flex-direction: row;
        width: 95%;
        height: auto;
        margin: auto;
        margin-top: 50px;
        overflow-x: auto; 
         white-space: nowrap;
    }
    
    .showmobilecomp{
        border-bottom: 3px solid #6fdbc2;
    }
    
    .hidemobilecomp{
        border-bottom: 3px solid #233554;
    }

    .main-exp{
        display: block;
        margin-top: 50px;
    }
}