
.flexdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
}

#pic {
  width: 330px;
  height: 330px;
  border-radius: 50%;
}

.about {
  color: #ccd6f6;
  font-size: 1.4rem;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;;
  line-height: 1.3;
}
.aboutdiv {
  width: 80%;
}

.keyword {
  text-decoration: none;
}
.card-content {
  text-align: center;
}

#card-holder {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}
@media screen and (max-width: 415px) {
  #card-holder{
      width: 100%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  }
}
.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 340px; 
  background-color: rgba(10, 25, 40, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 80px;
  transition: transform 0.3s ease;
}
.card:hover{
  transform: translateY(-10px);
  background-color: #112240;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.card-image {
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  height: 200px;
}

.cardheading{
    margin-bottom: 25px;
}
    
.alllogodiv{
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    flex-wrap: wrap;
}
.singlelogo{
    width: 40px;
    margin-left: 10px;
    margin-right: 15px;
    margin-bottom: 20px;
    display: inline-block;
    
}

.logolabel{
    color: #a8b2d1;
    margin: 0px;
    padding: 0px;
}
.logoimg {
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}
#downarrow2{
    display: none;
}
.head{
  text-align: center;
  color: #a8b2d1;
}
@media screen and (min-width: 576px) {
  .flexdiv{
    margin-top: 190px;
    height: 100vh;
  }
}
@media screen and (min-width: 600px) and (max-width: 1024px) and (min-height: 750px) {
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
  #downarrow2{
    display: block;
    padding-top: 0px;
    position: absolute;
    animation: bounce 2s infinite;
    user-select: none;
    top: 90vh;
    left: 50vw;
}
.flexdiv{
  padding-top: 15%;

}
}
@media screen and (min-width: 1050px) {
  .flexdiv {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    margin: auto;
    height: 100vh;
    padding-top: 0;
  }

  #profileimg {
    margin: auto;
    text-align: center;
  }

  #profileimg {
    width: 40%;
  }
  .aboutdiv {
    width: 60%;
  }

  .top-space {
    margin-top: 250px;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
  #downarrow2{
    display: block;
    padding-top: 0px;
    position: absolute;
    animation: bounce 2s infinite;
    user-select: none;
    top: 90vh;
    left: 50vw;
}


}
