.contact-form   {
    width: 70%;
    height: auto;
    margin: auto;
    user-select: none;
    margin-top: 150px;;
}


.contacthead{
    text-align: center;
    color:#cdd0db;
    font-size: 2.3rem;
}

.gifdiv2{
    user-select: none;
    position: relative;
    bottom: 300px;
    left:65vw;
    height: 100%;
    z-index: -1;
}   
.gif2{
    height: 200px;     
    align-self: start;
   
}
.inputdiv{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}
.label{
    /* align-self: ; */
    position: relative;
    left: 26%;
    user-select: none;
    z-index: 2;
    color: aliceblue;
    /* border: 1px solid green; */
    display: inline;
}
.input{
    width: 50%;
    height: 40px;
    border: 1px solid #ccc; 
    outline: none;
    border-radius: 20px;
    background-color: #112240;
    align-self: center;
    padding: 10px;
    color: whitesmoke;
}
.messagearea{
    height: 150px;
    resize: none; 
}
.messagearea::-webkit-scrollbar {
    width: 10px;
}

.messagearea::-webkit-scrollbar-track {
    background: #31589b;
    border-radius: 10px;
}

.messagearea::-webkit-scrollbar-thumb {
    background: #007BFF;
    border-radius: 10px;
}

.messagearea::-webkit-scrollbar-thumb:hover {
    background: #0056b3;
}
.send{
   text-align: center;
   height: auto;
   padding-top: 20px;
}

.sendbutton{
    height: 30px;
    border-radius: 20px;
    border: 1px solid #ccc;
    background-color: #112240;
    color: whitesmoke;
    width: 100px;
}
body {
    margin: 0;
    overflow-x: hidden;
  }

@media screen and (max-width: 900px) {
    .input{
        width: 80%;
    }

    .label{
        left: 12%;
    }
}

@media screen and (max-width: 600px) {
    .contact-form   {
        margin-top: 100px;
    }
    .input{
        width: 100%;
    }

        .gifdiv2{
            display: none;
        }

    .label{
        left: 3%;
    }
}