.footer-main{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    user-select: none;
    margin-bottom: 10px;
}

.made-by{
    color: #dbd6e3;
}

.social{
    display: flex;
    flex-direction: row;
    margin-left: 40vw;
    color: #dbd6e3;
    
}

.github, .linkedin{
    color: #dbd6e3;
    text-decoration: none;
    font-size: large;
}
.github{
    margin-left: 20px;
}
.github:hover, .linkedin:hover {
    color: #6fdbc2;
}

@media screen and (max-width: 900px) {
    .social{
        display: none;
    }   
    .made-by{
        margin: auto;
    }
}