/* When want to bring back old changes(hamburger menu) to nav bar, take out line 4 to 53 
change line 68 to show, remove comment from 130 to 132, */
#desktopnav {
  position: fixed;
  top: 0px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  height: 6vh;
  width: 100%;
  min-width: 250px;
  align-items: center;
  margin: auto;
  border-radius: 999px;
  overflow: hidden;
  padding: 0 1rem; /* Add padding for better spacing */
  background-color: #0a192f; /* Matching dark background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: none;
}
.desktophome {
  width: 25%;
  height: 100%;
  font-size: x-large;
  user-select: none;
  display: flex;
  align-items: center;
  padding-left: 2%;
  background-color: #0a192f; /* Matching background color */
  color: #ffffff; /* White text for contrast */
  cursor: pointer;
}

.desktopother {
  width: 80%;
  height: 6vh;
  background-color: #0a192f; /* Matching background color */
  color: #ffffff; /* White text for contrast */
  font-size: x-large;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-align: start;
}

.sectionclassdesktop {
  color: #ffffff; /* White text for contrast */
  padding-left: 30px;
  user-select: none;
  display: flex;
    align-items: center;
  cursor: pointer;
}

.navbar {
  width: 100%;
  height: 6vh;
  background-color: #0a192f;
  margin: 0;
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
}

/* selecting an element having both the classes present in it */
.otherlinks.show {
  display: none;
}

.otherlinks.hide {
  display: none;
}

.navbarmaindiv {
  min-width: 230px;
  margin-top: 40px;
}
.hamburger-menu {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30px;
  height: 24px;
  padding: 0;
}

.line {
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  border-radius: 10px;
}

.line:not(:last-child) {
  margin-bottom: 4px;
}

.otherlinks {
  background-color: #0a192f;
  display: flex;
  flex-direction: column;
  opacity: 0.8;
  align-items: center;
  opacity: 0.8;
  width: 100%;
  padding: 1rem 0;
  padding-top: 0;
  font-size: large;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
}

.sectionclass {
  width: 80%;
  text-align: center;
  color: #ffffff;
  user-select: none;
  border-bottom: 2px solid;
  padding: 0.5rem 0;
}

.sectionclass:last-child {
  border-bottom: none;
}

/* #desktopnav {
    display: none;
} */
.social-links {
  display: flex;
  justify-content: start;
  gap: 20px;
  user-select: none;
}

.social-logo {
  width: 35px;
  height: 35px;
}
.showcolor {
  color: #6fdbc2;
  font-weight:bolder; /* Make the font bold */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3), 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease; /* Smooth transition for the changes */
}

.hidecolor {
  color: #ffffff;
  animation: pulse 2s infinite;
}

.hidecolor:hover {
  transform: scale(1.02);
}


@keyframes pulse {
  0% {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
50% {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
}
100% {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
}
@media screen and (min-width: 576px) {
  .otherlinks {
    display: none !important;
  }
  .navbar {
    display: none;
  }

  #desktopnav {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    height: 6vh;
    width: 100%;  
  
    align-items: center;
    margin: auto;
    border-radius: 999px;
    overflow: hidden;
    padding: 0 1rem; /* Add padding for better spacing */
    background-color: #0a192f; /* Matching dark background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: top 0.3s ease-in-out;
  }

  .desktophome {
    width: 20%;
    height: 100%;
    font-size: x-large;
    display: flex;
    align-items: center;
    user-select: none;
    margin-left: 9%;
    background-color: #0a192f; /* Matching background color */
    color: #ffffff; /* White text for contrast */
  }

  .desktopother {
    width: 80%;
    height: 6vh;
    background-color: #0a192f; /* Matching background color */
    color: #ffffff; /* White text for contrast */
    font-size: x-large;
    margin: 0;
    margin-right: 10%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    text-align: start;
  }

  .sectionclassdesktop {
    color: #ffffff; /* White text for contrast */
    padding-left: 30px;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }
  .showcolor {
    color: #6fdbc2;
    font-weight:bolder; /* Make the font bold */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3), 2px 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Smooth transition for the changes */
  }

  .hidecolor {
    color: #ffffff;
    animation: pulse 1s infinite;
  }

  .hidecolor:hover {
    transform: scale(1.02);
  }


  @keyframes pulse {
    0% {
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  50% {
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  }
  100% {
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}

}
