@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.project-div {
  width: 100%;
  height: auto;
  user-select: none;
}

#projecthead {
  color: azure;
  text-align: center;
  font-size: 2.5rem;
  font-family: Arial, Helvetica, sans-serif;
}

.desc {
  font-size: larger;
  color: #8892b0;
  
  text-align: left;
  font-family: montserrat, sans-serif;
}
.individualproject {
  padding: 30px;
  display: flex;
  flex-direction: column;
  background-color: rgba(10, 25, 40, 0.8);
  border: 3px solid ;
  width: 90%;
  margin:auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 40px;
  
}


.individualproject:hover {
  background-color: #112240;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
}
.individualproject:hover .photodiv, .individualproject:hover .descriptiondiv {
  opacity: 1;   
 
}
.projectcontainer {
  display: flex;
  flex-direction: column;
}
.projtitle {
  color: aliceblue;
  text-align: center;
  margin: 0px;
  font-family:'Roboto', sans-serif;
}
.photodiv{
    margin-bottom: 30px;
}
.git{
    text-transform: uppercase;
    text-decoration: none;
    user-select: none;
    color: aliceblue;
    font-family:Montserrat;
    display: inline-block;
    padding-bottom: 1rem;
}

.live{
    display: inline-block;
    padding-left: 30px !important;
    font-family:Montserrat;
}
  
  .projtitle.live:hover, .projtitle.git:hover, .git:hover {   
    color: #007bff; 
    opacity: 0.8;
  }
  .keyword {
    font-weight: bold;
    color: #6fdbc2;
  }
  
@media screen and (min-width: 900px) {

  .head{
    font-size: 3.5rem;
  }

  .individualproject {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-self: center;
    background-color: #0a1928;
    padding: 0;
    margin-bottom: 40px;
    border: none ;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    
  }

  #right{
    flex-direction: row-reverse;
  }


  .photodiv {
    margin-top: 40px;
    width: 45%;
    box-shadow: inset;
  }

  .descriptiondiv {
    padding-top: 30px;
    width: 55%;
  }

  .desc {
    padding-left: 10%;
    text-align: justify;
    padding-right: 25px;
  }
  .projtitle {
    padding-left: 10%;
    text-align: left;
  }
  .titletoleft{
    text-align: justify;
    padding-left: 20px;
    padding-right: 10%;
  }
  .left{
    padding-left: 20px;
  }
}
